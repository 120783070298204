import { routes } from '@shared/constants/routes'
import { resetAnalytics } from '@/features/shared/analytics/utils'
import { clear } from '@/features/shared/utils/localStorage'
import { isOnServer } from '@shared/constants/util'
import { getQueryClient } from '@/features/shared/utils/dataFetching/reactQuery/SegwayQueryClient'
import { removeGuestCookies } from '@/features/shared/context/GuestUserContext'
import {
  type LogoutOptions,
  getAuthClient,
} from '@/features/authentication/utils/authentication/client'
import { csDeleteStoreParams } from '@/lib/contentsquare'
import Bugsnag from '@bugsnag/js'
import dompurify from 'isomorphic-dompurify'
import { datadogRum } from '@datadog/browser-rum-slim'

export const resetUser = ({
  redirectUrl = routes.LOGOUT.url,
}: LogoutOptions = {}) => {
  if (isOnServer()) return
  removeGuestCookies()
  resetAnalytics()
  csDeleteStoreParams()
  getQueryClient().clear()
  clear()
  Bugsnag.setUser()
  datadogRum.clearUser()
  Bugsnag.clearMetadata('user')
  window.location.href = dompurify.sanitize(redirectUrl)
}

export const logout = (options: LogoutOptions = {}) => {
  return getAuthClient().logout(options)
}

import { useQueryFeatureFlags } from '@/features/shared/services/FeatureFlags/queries'
import { featureFlagsAtom } from '@/features/shared/state/FeatureFlags/atoms'
import { logError } from '@/features/shared/utils/logger'
import { useAtomValue, useSetAtom } from 'jotai'
import { useHydrateAtoms } from 'jotai/utils'
import { useEffect } from 'react'
import { defaultFlags } from '@/features/shared/services/FeatureFlags/constants'
import { datadogRum } from '@datadog/browser-rum-slim'

export const useLoadFeatureFlagsIntoAtom = () => {
  const setFeatureFlags = useSetAtom(featureFlagsAtom)
  const { isFetched, isPending, error, data } = useQueryFeatureFlags()

  // hydrate jotai atom from hydrated feature flags query
  useHydrateAtoms([
    [
      featureFlagsAtom,
      {
        error,
        isFetched,
        isPending,
        flags: data || defaultFlags,
      },
    ],
  ])

  useEffect(() => {
    setFeatureFlags({
      isPending,
      error,
      isFetched,
      flags: data || defaultFlags,
    })
  }, [data, isPending, error, isFetched, setFeatureFlags])

  return null
}

export const useSyncFeatureFlagsWithGlobalVariable = () => {
  const { flags, isPending } = useAtomValue(featureFlagsAtom)

  useEffect(() => {
    if (!isPending) {
      try {
        Object.entries(flags).forEach(([key, value]) => {
          // e.g. __SEGWAY_FEATURE_FLAG_real_time_order_tracking=true
          // @ts-expect-error indexing window variable
          window[`__SEGWAY_FEATURE_FLAG_${key}`] = value
          datadogRum.addFeatureFlagEvaluation(`ff_${key}`, value)
        })
      } catch (e) {
        // dont wanna accidentally bring down the app, need alerting around this error
        logError(
          new Error('Unable to set feature flag global variables'),
          {},
          {
            cause: e,
          }
        )
      }
    }
  }, [isPending, flags])
}

import { useUser } from '@/features/account/services/User/hooks'
import { useEffect } from 'react'
import Bugsnag from '@bugsnag/js'
import { getAnonymousId } from '@shipt/analytics-member-web'
import { setCookie } from '@/features/shared/utils/cookies'
import { UNIVERSAL_ID } from '@shared/constants/segment'
import { datadogRum } from '@datadog/browser-rum-slim'

export const useMonitorUser = () => {
  const user = useUser()
  const { id, universal_id, has_purchased, has_placed_marketplace_order } =
    user ?? {}
  const subscriptionType = user?.subscription?.type

  useEffect(() => {
    if (!id || !universal_id) return

    window.dtrum?.identifyUser(String(id))

    const anonymousId = getAnonymousId()
    const userId = String(id)
    Bugsnag.setUser(userId)
    Bugsnag.addMetadata('user', {
      anonymousId,
      universalId: universal_id,
      hasPlacedMarketplaceOrder: has_placed_marketplace_order,
      hasPurchased: has_purchased,
      subscriptionType,
    })
    datadogRum.setUser({
      id: userId,
      anonymousId,
      universalId: universal_id,
      hasPlacedMarketplaceOrder: has_placed_marketplace_order,
      hasPurchased: has_purchased,
      subscriptionType,
    })
    // set universal_id cookie once per user
    setCookie(UNIVERSAL_ID, universal_id, { expires: 365 })
  }, [
    id,
    universal_id,
    has_placed_marketplace_order,
    subscriptionType,
    has_purchased,
  ])
}

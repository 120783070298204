// aristotle experiments - editable in optimizely
// when we add or remove values from this file, we'll want to update Dynatrace to add/remove the experiment to session tracking
export enum ExperimentType {
  // https://app.optimizely.com/v2/projects/19950886887/experiments/28840110325/variations
  // this project is scheduled to be picked up again in Q2 of 2025
  ADD_PAST_SHOPPERS_AS_PREFERRED = 'add_past_shoppers_as_preferred',

  // https://app.optimizely.com/v2/projects/19950886887/flags/manage/delivery_window_options/rules/development/edit/delivery_window_options
  // project slack channel: https://shipt.enterprise.slack.com/archives/C081RDR1H8S
  DELIVERY_WINDOW_OPTIONS = 'delivery_window_options',

  // https://app.optimizely.com/v2/projects/19950886887/flags/manage/new_stores_banner_on_ghp
  // experiment is still collecting data
  NEW_STORES_BANNER = 'new_stores_banner',

  // https://app.optimizely.com/v2/projects/19950886887/flags/manage/new_users_faster_checkout_app/rules/development
  // project slack channel: https://shipt.enterprise.slack.com/archives/C08JPLZ557Z
  NEW_USERS_FASTER_CHECKOUT = 'new_users_faster_checkout_app',

  // https://app.optimizely.com/v2/projects/19950886887/flags/manage/no_markups_on_everyday_essentials/rules/development/edit/no_markups_on_everyday_essentials
  // project slack channel: https://shipt.enterprise.slack.com/archives/C07SLL595UP (#crew-markup-and-fees)
  NO_MARKUPS_ON_EVERYDAY_ESSENTIALS = 'no_markups_on_everyday_essentials',

  // https://app.optimizely.com/v2/projects/19950886887/experiments/29267450185/variations
  // this experiment is semi permanent in lieu of back end support for this feature
  NO_MARKUP_TEST = 'no_markup_test',

  // https://app.optimizely.com/v2/projects/19950886887/flags/manage/product_card_revamp/rules/development
  PRODUCT_CARD_REVAMP = 'product_card_revamp',

  // https://app.optimizely.com/v2/projects/19950886887/flags/manage/progressive_reveal/rules/development
  PROGRESSIVE_REVEAL = 'progressive_reveal',

  // https://app.optimizely.com/v2/projects/19950886887/flags/manage/ratings_and_reviews_frontend/rules/development
  // project slack channel: https://shipt.enterprise.slack.com/archives/C07TK20T14K (#proj-ratings-and-reviews)
  RATINGS_AND_REVIEWS = 'ratings_and_reviews_frontend',

  //https://app.optimizely.com/v2/projects/19950886887/flags/manage/stores_list_large_tiles_vs_small_tiles/rules/development
  STORES_LIST_SMALL_TILES = 'large_vs_small_tiles',
}
